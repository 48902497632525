import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExternaluserredirectionComponent } from './externaluserredirection/externaluserredirection.component';
import { LoginComponent } from './login';
import { ChangepasswordComponent } from './user/profile/myprofile/changepassword/changepassword.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'submit/abstract/external', component: ExternaluserredirectionComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'register', loadChildren: () => import('./register/register.module').then( (m) => m.RegisterModule) },
  { path: 'myprofile', loadChildren: () => import('./user/profile/profile.module').then( (m) => m.ProfileModule) },
  { path: 'abstract', loadChildren: () => import('./user/abstract/abstract.module').then( (m) => m.AbstractSubmissionModule) },
  { path: 'fullpaper' , loadChildren: () => import('./fullpaper/fullpaper.module').then ((m)=> m.FullPaperModule)},
  { path: 'fullpaper/update', loadChildren: () => import('./ckeditor-fullscreen-view/ckeditorfullscreen.module').then( (m) => m.CkEditorFullScreenModule) }, 
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
