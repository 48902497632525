import { ModuleWithProviders, NgModule } from '@angular/core';
import { CalendarUtilsService } from '../../src/utils/calendar.utils';
// import { DragDropDirective } from '../directives/dragdrop.directive';
import { LoaderService } from '../core/loader/loader.service';
import { MessageService } from '../core/message/message.service';
import { RestService } from '../core/rest.service';
import { AbstractService } from './abstract.service';
import { ArticleService } from './article.service';
import { AuthService } from './auth.service';
import { ClientService } from './client.service';
import { CommunicationService } from './communication.service';
import { ConferenceService } from './conference.service';
import { EventsService } from './events.service';
import { GroupService } from './group.service';
import { IssuesService } from './issues.service';
import { JournalService } from './journal.service';
import { TaskService } from './task.service';
import { UserService } from './user.service';
import { WorkflowDataService } from './workflow.service';
import { AppService } from 'src/app/app.service';
import { FullPaperService } from './fullpaper.service';


// to share single instance of services across all modules and components
@NgModule({})
export class SharedServiceModule {
  static forRoot(): ModuleWithProviders<SharedServiceModule> {
    return {
      ngModule: SharedServiceModule,
      providers: [
        // DragDropDirective,
        ArticleService,
        ClientService,
        CommunicationService,
        GroupService,
        IssuesService,
        JournalService,
        AbstractService,
        ConferenceService,
        EventsService,
        WorkflowDataService,
        FullPaperService,
        TaskService,
        RestService,
        LoaderService,
        MessageService,
        AuthService,
        UserService,
        CalendarUtilsService,
        AppService
      ],
    };
  }
}
