import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class FullPaperService {

  constructor(private restService: RestService) { }

  // public uploadFullPaper(formData,abstractNodeId) {
  //   return this.restService
  //     .post(`${AppConfig.BASE_URL_V1}/abstract/msrim/fullPaperUpload/${abstractNodeId}`, formData, null, true, true).
  //     pipe(map((res: any) => res));
  // }
  public uploadFullPaper(formData,abstractNodeId) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/abstract/msrim/fullPaperUpload/' +abstractNodeId , formData, null, true, false).
      pipe(map((res: any) => res));
  }
  public getFullPaperContent(nodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/' + nodeId + '/content', null).pipe(map((res: any) => res));
  }
  public updateFullPaperContent(nodeId, content) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/msrim/content/' + nodeId , content, false, false, true).pipe(map((res: any) => res));
  }
  public submitFullPaper(nodeId,data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/msrim/content/' + nodeId , data, null).pipe(map((res: any) => res));
  }
  public reSubmitFullPaper(nodeId,data) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/msrim/fullPaper/resubmit/' + nodeId ,data, null).pipe(map((res: any) => res));
  }
  public submitFullPaperForReview(nodeId,data) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/msrim/fullPaper/submit/' + nodeId ,data, null).pipe(map((res: any) => res));
  }
}