<a href="javascript:void(0)" class="link_btn" (click)="openGuidelinesModal()" data-mdb-toggle="modal"
	data-mdb-target="#guidlineModal">View Guideline</a>


<!-- Guidline Modal -->
<div class="modal fade" bsModal #guidlineModal="bs-modal" id="guidlineModal" tabindex="-1"
	aria-labelledby="guidlineModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="guidlineModalLabel">Abstract Guidelines</h5>
				<button type="button" class="btn-close" (click)="guidlineModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
					<p>The manuscript should adhere to the following requirements.</p>

                    <p><strong>Font style:</strong> Times New Roman</p>

                    <p><strong>Font size:</strong> 12</p>

                    <p><strong>Line spacing:</strong> 1.5</p>

                    <p><strong>Title page:&nbsp;</strong>Title of the manuscript, Author&#39;s First Name and Second
                        Name (no prefix/suffix), Affiliations, Corresponding author details: email, phone number and
                        address.</p>

                    <p><strong>Abstract and Keywords:</strong> World limit is 200-250 words, Not more than 5 key words
                    </p>

                    <p><strong>Main text:</strong> Length of the Manuscript: 3000-5000 words (including abstract,
                        excluding references, &amp; acknowledgment)</p>

                    <p>The full paper should include an Introduction, Methodology, Result, Discussion, Conclusion,&nbsp;
                    </p>

                    <p><strong>Tables and Figures:</strong> Should have self-explanatory titles and be numbered as
                        referred to in the text. The sources, wherever necessary, should be mentioned at the bottom of
                        the tables/charts/graphs.&nbsp;</p>

                    <p><strong>Acknowledgements:</strong> If there are any acknowledgement to be mentioned should not be
                        more than 20 words</p>

                    <p><strong>Reference Style: </strong>APA 7th edition</p>

                    <p><strong>Note:</strong><br />
                        A compendium of the abstracts will be published by the Institute under the conference theme
                        &ldquo;Environment, Social and Governance (ICETBS)&rdquo; December 2024 with an ISBN number.</p>

                    <ul>
                        <li>Selected papers will be recommended to high-quality indexed journals/considered for
                            publication in M S Ramaiah Management Review (having ISSN and indexed in Research gate).
                        </li>
                        <li>Selected papers may be recommended to journals listed in WoS for publication at an
                            additional publication charge to the authors.</li>
                    </ul>

                    <p>&nbsp;&nbsp;<br />
                        <strong>Registration</strong><br />
                        In case of multiple authors, each author must be registered by paying the registration fees as
                        applicable
                    </p>

                    <p>&nbsp;</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn app_btn btn_close" (click)="guidlineModal.hide()"
					data-mdb-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--/. Guidline Modal -->