<header class="app_header">
    <div class="header_flex">
        <div class="header_left">
            <button class="btn btn_link btn-lg btn-floating mob_nav_trigger">
                <i class="ri-menu-2-line"></i>
            </button>

            <a (click)="onClickMysubmission()" class="btn btn_link btn-lg btn-floating header_back_btn">
                <i class="ri-arrow-left-line"></i>
            </a>
            <div class="brand_icon">
                <img src="../../../../assets/img/logo.png">
            </div>
        </div>
        <div class="header_right">
            <div class="mob_nav_overlay">
                <button class="btn btn_link btn-lg btn-floating mob_nav_close">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="header_nav">
                <ul>
                    <li><a href="javascript:void(0)" class="link_btn" (click)="openGuideLineModal()">View Guidelines</a></li>
                    <li><a href="javascript:void(0)" class="link_btn">Contact Details</a></li>
                    <li class="mob_show"><a href="javascript:void(0)" class="link_btn">About ICETBS 2024</a></li>
                </ul>
            </div>
            
            
			<div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn_link header_user dropdown-toggle dropdown-toggle-split"
                    id="dropdownBasic1" ngbDropdownToggle>
                    <div class="user_circle">
                        <i class="ri-user-line" aria-hidden="true"></i>
                        <!-- <img src="../../../assets/img/avatar.png"> -->
                    </div>
                    <div class="user_body">
                        <span class="name">{{userName}}</span>
                        <span class="email">{{loginedUserEmail}}</span>
                    </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <!-- <button ngbDropdownItem><a class="dropdown-item unlink">
                            {{userName}}<br>
                            {{loginedUserId}}
                        </a></button> -->
                    <button ngbDropdownItem><a class="dropdown-item" (click)="logoutConfirm()">Logout</a></button>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="wrap_fluid">
    
    <div class="wrap_content">
        <div class="wrap_container">
			<form novalidate [formGroup]="formGroup">                
				<div>
				  <div class="card card-default">
					<div class="card-header">
					  <h4 class="card-title">
						<i class="fa fa-user-secret"></i>  Change Password
					  </h4>
					</div>
					<div class="card-body">
					  <span class="form-text text-muted"> You will need to login again after changing the password.</span>
					  <br />
					  <div class="form-group">
						<label class="col-form-label" for="UserName"> User I.D/Email</label>
						<div>
						  <span class="form-control  ">{{loginedUserEmail}}</span>
						  <input  type="hidden" name="UserName" formControlName="userName" />
						</div>
					  </div>
					  <!-- <div class="form-group">
						<label class="col-form-label" for="OldPassword"> Old Password</label>
						<div>
						  <input pInputText maxlength="50" class="form-control" formControlName="oldPassword" id="OldPassword" name="OldPassword"
							type="password" />
						</div>
						<div class="forms_error_info" *ngIf="formGroup.controls['oldPassword'].invalid && formGroup.controls['oldPassword'].dirty">
						  <i class="fa fa-close mr-1"></i>
						  <span *ngIf="formGroup.controls['oldPassword'].errors.required">Old Password is required!</span>
						  <span *ngIf="formGroup.controls['oldPassword'].errors.minlength">  Old Password must be 8 characters or more</span>
						  <span *ngIf="formGroup.controls['oldPassword'].errors.maxlength"> Old Password is too long</span>
						</div>
					  </div> -->
					  <div class="form-group">
						<label class="col-form-label" for="Password"> Password</label>
						<div>
						  <input pInputText maxlength="50" class="form-control" 
							id="Password" formControlName="password"   name="Password" type="password"/>
						</div>
						<div class="forms_error_info" *ngIf="formGroup.controls['password'].invalid && formGroup.controls['password'].dirty">
						  <i class="fa fa-close mr-1"></i>
						  <span *ngIf="formGroup.controls['password'].errors.required">Password is required</span>
						  <span *ngIf="formGroup.controls['password'].errors.minlength">Password must be 8 characters or more</span>
						  <span *ngIf="formGroup.controls['password'].errors.maxlength">Password is too long</span>
						</div>
					  </div>
					  <div class="form-group">
						<label class="col-form-label" for="ConfirmPassword"> Confirm Password</label>
						<div>
						  <input pInputText maxlength="50" class="form-control"
						   formControlName="confirmPassword" id="ConfirmPassword" name="ConfirmPassword" type="password" />
						</div>
						<div class="forms_error_info" *ngIf="formGroup.controls['confirmPassword'].invalid && formGroup.controls['confirmPassword'].dirty">
						  <i class="fa fa-close mr-1"></i>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.required">Password is required</span>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.minlength">Password must be 8 characters or more</span>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.maxlength">Password is too long</span>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.PasswordMisMatch && !formGroup.controls['confirmPassword'].errors.required &&  !formGroup.controls['confirmPassword'].errors.minlength && !formGroup.controls['confirmPassword'].errors.maxlength">Passwords don't match</span>               
						</div>
					  </div>
					</div>
					<div class="card-footer">
						<div class="row">
							<div class="col text-right">
								<button class="btn btn-outline-primary mr-2" (click)="onClickMysubmission()"> Cancel</button>
								<button class="btn btn-primary" (click)="onSubmit(formGroup.value)"> Submit</button>
							</div>
						</div>
						
					</div>
				  </div>
				</div>
			  </form>
        </div>
    </div>

    <!-- App Footer -->
    <footer class="app_footer">
        <div class="footer_flex">
            <p>©IASGCON 2024 - All rights reserved.</p>
            <!-- <ul>
                <li><a href="https://www.isshp2023.com/privacy-policy.html">Privacy Policy</a></li>
                <li><a href="javascript:void(0)">Terms &amp; Conditions</a></li>
            </ul> -->
        </div>
    </footer>
    <!--/. App Footer -->
</div>

<!-- Guidline Modal -->
<div class="modal fade" bsModal #guidlineModal="bs-modal" id="guidlineModal" tabindex="-1"
	aria-labelledby="guidlineModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="guidlineModalLabel">Abstract Guidelines</h5>
				<button type="button" class="btn-close" (click)="guidlineModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
                    <p>The manuscript should adhere to the following requirements.</p>

                    <p><strong>Font style:</strong> Times New Roman</p>

                    <p><strong>Font size:</strong> 12</p>

                    <p><strong>Line spacing:</strong> 1.5</p>

                    <p><strong>Title page:&nbsp;</strong>Title of the manuscript, Author&#39;s First Name and Second
                        Name (no prefix/suffix), Affiliations, Corresponding author details: email, phone number and
                        address.</p>

                    <p><strong>Abstract and Keywords:</strong> World limit is 200-250 words, Not more than 5 key words
                    </p>

                    <p><strong>Main text:</strong> Length of the Manuscript: 3000-5000 words (including abstract,
                        excluding references, &amp; acknowledgment)</p>

                    <p>The full paper should include an Introduction, Methodology, Result, Discussion, Conclusion,&nbsp;
                    </p>

                    <p><strong>Tables and Figures:</strong> Should have self-explanatory titles and be numbered as
                        referred to in the text. The sources, wherever necessary, should be mentioned at the bottom of
                        the tables/charts/graphs.&nbsp;</p>

                    <p><strong>Acknowledgements:</strong> If there are any acknowledgement to be mentioned should not be
                        more than 20 words</p>

                    <p><strong>Reference Style: </strong>APA 7th edition</p>

                    <p><strong>Note:</strong><br />
                        A compendium of the abstracts will be published by the Institute under the conference theme
                        &ldquo;Environment, Social and Governance (ICETBS)&rdquo; December 2024 with an ISBN number.</p>

                    <ul>
                        <li>Selected papers will be recommended to high-quality indexed journals/considered for
                            publication in M S Ramaiah Management Review (having ISSN and indexed in Research gate).
                        </li>
                        <li>Selected papers may be recommended to journals listed in WoS for publication at an
                            additional publication charge to the authors.</li>
                    </ul>

                    <p>&nbsp;&nbsp;<br />
                        <strong>Registration</strong><br />
                        In case of multiple authors, each author must be registered by paying the registration fees as
                        applicable
                    </p>

                    <p>&nbsp;</p>
                </div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn app_btn btn_close" (click)="guidlineModal.hide()"
					data-mdb-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--/. Guidline Modal -->