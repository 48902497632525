<app-message></app-message>
<app-loader></app-loader>
 <router-outlet></router-outlet>
<!-- <div class="abstract_wrap">
  <div class="abstract_grid">
    
    <div class="abstract_banner">
      <div class="wrap_banner_content">
        <div class="sidebar_head">
          <h1>Abstract <br />Submission</h1>
        </div>
        <div class="sidebar_body">
          <div class="brand_icon">
            <img src="../assets/img/MSRIM_logo.png">
            <img src="../assets/img/bcic-no-bg.png">
          </div>

          <div class="banner_title">
            <h2>
              INTERNATIONAL
 CONFERENCE ON
 EMERGING TECHNOLOGIES, 
BUSINESS & SOCIETY
            </h2>
          </div>
          <div class="banner_info">
            <div class="banner_icon_list">
              <div class="list_icon">
                <i class="ri-calendar-check-line"></i>
              </div>
              <h5> December 4-5, 2024<br /></h5>
            </div>
            <div class="banner_icon_list">
              <div class="list_icon">
                <i class="ri-map-pin-line"></i>
              </div>
              <h5>Ramaiah Institute of Management</h5>
            </div>
          </div>
        </div>
        <div class="sidebar_foot">
          <div class="powered_by">
            <span>Powered by</span>
            <img  src="../assets/img/scholarit_logo_white_rgb.png">
          </div>
        </div>
      </div>
    </div>

    <div class="abstract_content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->
